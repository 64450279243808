import Collapsible from "./Collapsible"

const Information = () => {
    return (
        <Collapsible>
            <b>Information</b>
            <ul>
                <li><a href="https://www.markdownguide.org/basic-syntax/">Markdown syntax</a></li>
            </ul>
        </Collapsible>
    )
}

export default Information