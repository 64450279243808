import { useEffect, useState } from "react"
import Config from "./Config"
import Collapsible from "./Collapsible"

const UserMenu = ({users,onAddUser,onUserClick}) => {

    const xs = users.map((user) => <li class="text-secondary" onClick={(e) => onUserClick(user)}>{user.username}</li>)

    const handleAddUser = e => {
        const username = prompt("Username")
        const password = prompt("Password")

        onAddUser({username:username,password:password})
        e.preventDefault()
    }

    return(
        <Collapsible>
            <b class="text-primary">Users</b>
            <ul>
                {users.map(x => <li>{x.username}</li>)}
                <li onClick={handleAddUser}>[ + Add a new User + ]</li>
            </ul>
        </Collapsible>
    )
}

export default UserMenu