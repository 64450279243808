import styled from "styled-components"

const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
`

const Left = styled.div`
    flex: ${props => props.weight}
`

const Right = styled.div`
    flex: ${props => props.weight};
`

const Split = ({children, leftWeight,rightWeight}) => {
    const [left,right] = children

    return(
        <Wrapper>
            <Left weight={leftWeight}>
                {left}
            </Left>
            <Right weight={rightWeight}>
                {right}
            </Right>
        </Wrapper>
    )
}

export default Split