import Collapsible from "./Collapsible"
import Information from "./Information"

const CardsMenu = ({cards, onCardClick, onAddCard}) => {

    const xs = cards.map((card) => <li class="text-secondary" onClick={(e) => onCardClick(card)}>{card.title}</li>)

    const handleAddCard = e => {
        e.preventDefault()
        onAddCard()
    }

    return (
        <Collapsible>
            <b class="text-primary">Cards</b>
            <ul>
                {xs}
                <li onClick={handleAddCard}>[ + Add a new card + ]</li>
            </ul>
        </Collapsible>
    )
}


export default CardsMenu