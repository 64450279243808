import styled from "styled-components"
import Split from "./Split"

const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    width: 100vw;
`
const Right = styled.div`
`

const Layout = ({children}) => {
    const [top,left,right] = children
    return (
        <Wrapper>
            {top}
            <Split leftWeight={1} rightWeight={5}>
                <div class="p-3 bg-light">
                    {left}
                </div>
                <Right>
                    {right}
                </Right>
            </Split>
        </Wrapper>
    )
}

export default Layout