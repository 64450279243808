import { useState } from "react"

const Collapsible = ({children}) => {
    const [header, togglable] = children

    const [state, setState] = useState(false)

    const ToggleState = (e) => setState(!state)

    return (
        <div>
            <div onClick={ToggleState}>
                {header}
            </div>
            <div>
                {state?togglable:''}
            </div>
        </div>
    )
}


export default Collapsible