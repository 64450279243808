import { useState } from "react"
import Config from "./Config"

const LoginForm = ({onSuccess, axios}) => {

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')

    const handleLogin = e => {
        e.preventDefault()
        axios.post(Config.api+'/login',{
            username:username,
            password:password
        }).then((res) => {
            onSuccess()
        }).catch(err => {
            alert('Could not sign in')
            console.log(err)
        })
    }

    return (
        <div>
            <h1>Settlehere admin</h1>
            <form>
                <div class="">
                    <label for="username">Username</label>
                    <input onChange={e => setUsername(e.target.value)} value={username} type="username" id="username" placeholder="Username"></input>
                </div>
                <div class="">
                    <label for="exampleInputPassword1">Password</label>
                    <input onChange={e => setPassword(e.target.value)} value={password} type="password" id="exampleInputPassword1" placeholder="Password" />
                </div>
                <div>
                    <button type="submit" class="btn btn-primary mt-5" onClick={handleLogin}>Login</button>
                </div>
            </form>
        </div>
    )
}

const LoginHandler = ({children,axios})  => {
    const [loggedIn,setLoggedIn] = useState(true)

    const checkAuth = () => {
        axios.get(Config.api+'/check').then(res => {
            const r = res.data == "ADMIN"
            //setLoggedIn(r)
        })

        setLoggedIn(true)
    }

    checkAuth()

    return(
        <>
            {loggedIn? children:<LoginForm axios={axios} onSuccess={checkAuth}/>}
        </>
     )
}

export default LoginHandler