import { useEffect, useState } from 'react';
import CardEditor from './CardEditor';
import CardsMenu from './CardsMenu';
import Layout from './Layout';
import Information from './Information'; 
import Config from './Config';
import UserMenu from './UserMenu';

const default_cards = [
    {
        id:0,
        title:'Find housing',
        content: 'lorem ipsum',
        lockers: []
    },
    {
        id:1,
        title:'Get phone number',
        content: 'lorem ipsum',
        lockers: [0]
    }
]

function App({axios}) {

    const [cards, setCards] = useState(default_cards)
    const [activeCard, setActiveCard] = useState('')
    const [users,setUsers] = useState([])

    const handleCardChange = card => {
        setActiveCard(old => card)
    }

    const handleAddUser = user => {
        const ys = [...users]
        axios.post(Config.api+'/user/create',user,{ withCredentials: true }).then(response =>{
            ys.push(user)
            setUsers(ys)
        }).catch(e => {
            alert("Something went wrong")
            console.log(e)
        })
    }

    const handleCardSave = updateCard => {
        const ys = [...cards]

        const index = ys.indexOf(ys.find(x => x._id == updateCard._id))

       ys[index] = updateCard

        axios.post(Config.api+'/card/update',updateCard,{ withCredentials: true }).then(response => setCards(ys)).catch(e => alert("Something went wrong"))
    }

    const handleAddCard = () => {
        axios.get(Config.api+'/card/new',{},{ withCredentials: true }).then(response => console.log("Added a new card")).catch(e => alert("Something went wrong"))
        setActiveCard(cards.pop())
    }

    useEffect(() => {
            axios.get(Config.api+'/card').then((response) => {
                setCards(response.data)
            })
        }, [activeCard]);

    useEffect(() => {
        axios.get(Config.api+'/user').then((response) => {
            setUsers(response.data)
        })
    }, []);

    return (
        <Layout>
            <nav class="navbar navbar-light bg-light shadow-sm p-3 rounded">
                <a class="navbar-brand mb-0 h1" href="#">Settlehere admin panel</a>
            </nav>
            <div>
                <CardsMenu cards={cards} onCardClick={handleCardChange} onAddCard={handleAddCard}/>
                <UserMenu users={users} onUserClick={u => alert(u.username)} onAddUser={handleAddUser}/>
                <Information/>
            </div>
            {activeCard ? <CardEditor onSave={handleCardSave} card={activeCard} cards={cards}/> : ''}
        </Layout>
    );
}

export default App;
