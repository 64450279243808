import { useEffect, useState } from "react"
import Showdown from "showdown"
import styled from "styled-components"
import Split from "./Split"
import ReactMarkdown from 'react-markdown'


const Wrapper = styled.div`
    margin: 2rem;
`

const SmallWrapper = styled.div`
    margin: 0.5rem;
`

const Input = ({label,value,onChange}) => {
    return(
        <div>
            <label>{label}</label>
            <input 
            value={value}
            name='title'
            onChange={onChange}
            />
        </div>
    )
}

const Center = styled.div`
    display:flex;
    justify-content:center;
`

const Preview = ({children, height}) => {

    const DynamicHeight = styled.div`
        height: ${props => props.height};
        width: 20rem;
        overflow-y: scroll
    `

    return (
        <SmallWrapper>
            <h3>Preview</h3>
            <hr/>
            <DynamicHeight height={height}>
                <ReactMarkdown>{children}</ReactMarkdown>
            </DynamicHeight>
        </SmallWrapper>
    )
}

const CardEditor = ({card, onSave, cards}) => {
    const [title,setTitle] = useState(card.title)
    const [content,setContent] = useState(card.content)
    const [lockers,setLockers] = useState(card.lockers)

    const [selection, setSelection] = useState(cards[0] ? cards[0]._id : null)

    useEffect(() => {
        setTitle(card.title)
        setContent(card.content)
        setLockers(card.lockers)
    },[card])

    const handleRemove = card => {
        const ys = [...lockers]
        const index = ys.indexOf(card)
        
        if(index == -1)
            return

        ys.splice(index,1)
        setLockers(ys) 
    }

    const handleAdd = e => {
        const ys = [...lockers]
        ys.push(selection)
        setLockers(ys)
    }

    const handleSave = e => {
        e.preventDefault()
        const updated_card = {
            'id':card.id,
            '_id':card._id,
            'title':title,
            'content':content,
            'lockers':lockers
        }

        onSave(updated_card)
    }

    const xs = lockers.map((x) =>{
        return (
        <tr>
            <td>{cards.find(y => y._id == x).title} ({x})</td>
            <td onClick={e => handleRemove(x)}>REMOVE</td>
        </tr>)
    })

    return (
        <Wrapper>
            <h2>Card editor</h2>
            <Wrapper>
                <form>
                    <Input label={'Title'} value={title} onChange={(e) => setTitle(e.target.value)}/>
                    <Split>
                        <SmallWrapper>
                            <h3>Content</h3>
                            <hr/>
                            <textarea  rows="20" cols="50" onChange={e => setContent(e.target.value)} value={content}></textarea>
                        </SmallWrapper>
                        <Preview height={"20rem"}>
                            {content}
                        </Preview>
                    </Split>

                    <table>
                        <thead>
                            <th colSpan={1}>Blocked by</th>
                            <th colSpan={1}>
                                <select class=" pt-1 pb-1" name="cards"  value={selection} onChange={e => setSelection(e.target.value)}>
                                    {cards.map(x => <option value={x._id}>{x.title}</option>)}
                                </select>
                                <button type="button" class="btn btn-primary mx-1 pt-1 pb-1" onClick={handleAdd}>Add</button>
                            </th>
                        </thead>
                        <tbody>
                            {xs}
                        </tbody>
                    </table>
                    <Center>
                        <button type="button" class="btn btn-primary mt-2" onClick={handleSave}>Save</button>
                    </Center>
                </form>
            </Wrapper>
        </Wrapper>
    )
}

export default CardEditor